import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { merge } from 'lodash-es';
import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';
import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';

@Injectable()
export class FuseConfirmationService {
    private _defaultConfig: FuseConfirmationConfig = {
        title: 'Confirm action',
        message: 'Are you sure you want to confirm this action?',
        icon: {
            show: true,
            name: 'heroicons_outline:exclamation',
            color: 'warn'
        },
        actions: {
            confirm: {
                show: true,
                label: 'Confirm',
                color: 'warn'
            },
            cancel: {
                show: true,
                label: 'Cancel'
            }
        },
        dismissible: false
    };

    /**
     * Constructor
     */
    constructor(
        private _matDialog: MatDialog
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    open(config: FuseConfirmationConfig = {}): MatDialogRef<FuseConfirmationDialogComponent> {
        // Merge the user config with the default config
        const userConfig = merge({}, this._defaultConfig, config);

        // Open the dialog
        return this._matDialog.open(FuseConfirmationDialogComponent, {
            autoFocus: false,
            disableClose: !userConfig.dismissible,
            data: userConfig,
            panelClass: 'fuse-confirmation-dialog-panel'
        });
    }

    openSuccess(message: string): MatDialogRef<FuseConfirmationDialogComponent> {

        var config = {
            title: 'Change Password',
            message: message,
            icon: {
                name: 'heroicons_outline:check-circle',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: false,
                },
                cancel: {
                    label: 'Close'
                }
            }
        } as FuseConfirmationConfig;

        // Merge the user config with the default config
        const userConfig = merge({}, this._defaultConfig, config);

        // Open the dialog
        return this._matDialog.open(FuseConfirmationDialogComponent, {
            autoFocus: false,
            disableClose: !userConfig.dismissible,
            data: userConfig,
            panelClass: 'fuse-confirmation-dialog-panel'
        });
    }

    openErrorMessage(message: string): MatDialogRef<FuseConfirmationDialogComponent> {
        var config = {
            title: 'Error',
            message: message,
            icon: {
                name: 'heroicons_outline:x-circle',
                color: 'warn'
            },
            actions: {
                confirm: {
                    show: false,
                },
                cancel: {
                    label: 'Close'
                }
            }
        } as FuseConfirmationConfig;

        // Merge the user config with the default config
        const userConfig = merge({}, this._defaultConfig, config);

        // Open the dialog
        return this._matDialog.open(FuseConfirmationDialogComponent, {
            autoFocus: false,
            disableClose: !userConfig.dismissible,
            data: userConfig,
            panelClass: 'fuse-confirmation-dialog-panel'
        });
    }

    openError(config: FuseConfirmationConfig = {}): MatDialogRef<FuseConfirmationDialogComponent> {
        config = {
            title: 'System Error',
            message: 'An error has occured. Please try again later.',
            actions: {
                confirm: {
                    show: false,
                },
                cancel: {
                    label: 'Close',
                    show: true
                }
            }
        };

        // Merge the user config with the default config
        const userConfig = merge({}, this._defaultConfig, config);

        // Open the dialog
        return this._matDialog.open(FuseConfirmationDialogComponent, {
            autoFocus: false,
            disableClose: !userConfig.dismissible,
            data: userConfig,
            panelClass: 'fuse-confirmation-dialog-panel'
        });
    }
}

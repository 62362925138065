/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        type: 'basic',
        icon: 'heroicons_outline:adjustments',
        link: '/dashboards/counter'
    },
    {
        id: 'payments',
        title: 'Payments',
        type: 'basic',
        icon: 'heroicons_outline:currency-dollar',
        link: '/payments'
    },
    {
        id: 'clients',
        title: 'Clients',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/clients'
    },
    {
        id: 'agents',
        title: 'Agents',
        type: 'basic',
        icon: 'heroicons_outline:user',
        link: '/agents'
    },
    {
        id: 'projects',
        title: 'Projects',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/projects'
    },
    {
        id: 'bank-accounts',
        title: 'Bank Accounts',
        type: 'basic',
        icon: 'heroicons_outline:library',
        link: '/bank-accounts'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
